/* global OfferKit */
/** @jsx jsx */
import { css, Global, jsx } from '@emotion/react'
import styled from '@emotion/styled'
import { useEffect } from 'react'

const STANDEN_BRONZE = '#b97c57'

const Background = styled.div`
  display: block;
  margin: 0;
  background-color: white;
  min-height: 100vh;
  width: 100vw;
`

const Header = styled.div`
  width: 100%;
  height: calc(125px - 12px);
  border-bottom: 1px solid black;
  background-color: black;
  padding: 0 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`

const GlobalStyles = css`
  html,
  body {
    font-family: 'Helvetica Neue', 'Helvetica', sans-serif;
  }

  .button-override {
    background: #00afef !important;
  }
`

const StyledLogo = styled.img`
  height: 85px;
`

const Container = styled.div`
  width: 82%;
  max-width: 1920px;
  margin: 0 auto;
`

const CarouselImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Button = styled.button`
  height: 48px;
  line-height: 46px;
  font-size: 16px;
  color: black;
  background-color: ${STANDEN_BRONZE};
  border: none;
  border-radius: 9999px;
  font-family: inherit;
  font-weight: 500;
  padding: 0 24px;

  letter-spacing: 0.02em;
  cursor: pointer;
`

const Standen = () => {
  useEffect(() => {
    OfferKit.exec('mount', {
      listingId: 'lst_offerkit-test-28-8-koorala',
      selector: '#make-an-offer',
    })
    return () => {
      OfferKit.exec('unmount')
    }
  }, [])

  return (
    <Background>
      <Global styles={GlobalStyles} />
      <div
        css={{
          borderBottom: `8px solid ${STANDEN_BRONZE}`,
          paddingBottom: '4px',
        }}
      >
        <Header>
          <div
            css={{
              display: 'flex',
            }}
          >
            <img
              css={{
                height: '85px',
                display: 'block',
              }}
              alt="standen logo"
              src="https://assets.boxdice.com.au/standengroup/attachments/059/a46/logo_icon.png?d91245b4722906e7c39edd082b071843"
            />
            <StyledLogo
              css={{ display: 'block' }}
              src="https://assets.boxdice.com.au/standengroup/attachments/095/724/logo_text.png?3af1e3dbd3db368bddb7b59633f58e05"
            />
          </div>
        </Header>
      </div>
      <div css={{ position: 'relative' }}>
        <div
          css={{
            background:
              'url(https://assets.boxdice.com.au/standengroup/attachments/9e9/27a/standen_estate_agents_banner_for_sale.jpg?c67fd3a56c0a6d877b8d627799a74e14&resize=1800x)',
            backgroundSize: 'cover',
            height: '400px',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            zIndex: '1',
          }}
        >
          <div
            css={{
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              width: '100%',
              height: '100%',
            }}
          ></div>
        </div>
        <div css={{ position: 'relative', zIndex: 2 }}>
          <Container style={{ paddingTop: '64px' }}>
            <h2
              css={{
                textTransform: 'uppercase',
                color: 'white',
                fontWeight: '100',
                letterSpacing: '0.04em',
                fontSize: '64px',
                margin: '12px 0',
                textAlign: 'center',
              }}
            >
              For Sale
            </h2>
            <AspectRatioBox ratio={19 / 8}>
              <CarouselImage
                src="https://assets.boxdice.com.au/standengroup/listings/619/e9272c86.jpg"
                width="1055"
                height="704"
                alt="house"
                style={{ marginBottom: '32px' }}
              />
            </AspectRatioBox>
            <div
              css={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                padding: '32px',
                gridGap: '32px',
              }}
            >
              <div>
                <h1
                  css={{
                    fontSize: '42px',
                    lineHeight: '1.1',
                    fontWeight: '300',
                    letterSpacing: '0.04em',
                    color: STANDEN_BRONZE,
                    margin: '12px 0',
                  }}
                >
                  The Perfect North To Rear Development Opportunity
                </h1>
                <div
                  css={{
                    borderBottom: '1px solid black',
                    width: '40%',
                    marginBottom: '12px',
                  }}
                ></div>
                <h2
                  css={{
                    fontWeight: '500',
                    fontSize: '24px',
                    margin: '8px 0',
                  }}
                >
                  35 Coolaroo Road, LANE COVE
                </h2>
                <div css={{ height: '32px' }} />
                <Button id="make-an-offer">Make an offer</Button>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </Background>
  )
}

export default Standen

function AspectRatioBox({ ratio, children, ...rest }) {
  return (
    <div
      css={{
        width: '100%',
        height: '0',
        paddingBottom: `calc(100% * (1 / ${ratio}))`,
        position: 'relative',
      }}
      {...rest}
    >
      <div
        css={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
        }}
      >
        {children}
      </div>
    </div>
  )
}

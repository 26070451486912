/* global OfferKit */
import { css, Global } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useEffect } from 'react'

const Background = styled.div`
  display: block;
  margin: 0;
  background-color: white;
  min-height: 100vh;
  width: 100vw;
`

const Header = styled.div`
  width: 100%;
  height: 110px;
  border-bottom: 1px solid black;
  background-color: white;
  padding: 0 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`

const GlobalStyles = css`
  html,
  body {
    font-family: 'Raleway', sans-serif;
  }

  .button-override {
    background: #00afef !important;
  }
`

const StyledLogo = styled.img`
  width: 259px;
`

const StyledAddress = styled.h1`
  font-family: 'Cardo', serif;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: bold;
  color: rgb(255, 0, 0);
`

const Container = styled.div`
  width: 76%;
  max-width: 1920px;
  margin: 0 auto;
`

const CarouselImage = styled.img`
  width: 100%;
  height: auto;
`

const Button = styled.button`
  height: 48px;
  line-height: 46px;
  font-size: 16px;
  color: #ff0000;
  border: 1px solid #ff0000;
  border-radius: 9999px;
  font-family: inherit;
  padding: 0 24px;
  background-color: white;
  transition: color 250ms, border-color 250ms black;
  letter-spacing: 0.02em;
  cursor: pointer;

  &:hover {
    color: black;
    border-color: black;
  }
`

const LJHSeaforth = () => {
  useEffect(() => {
    OfferKit.exec('mount', {
      listingId: 'lst_offerkit-test-28-8-koorala',
      selector: '#make-an-offer',
    })
    return () => {
      OfferKit.exec('unmount')
    }
  }, [])

  return (
    <Background>
      <Global styles={GlobalStyles} />
      <Header>
        <StyledLogo src="https://www.timwirth.com.au/wp-content/uploads/homepage_images/logo-ljh-seaforth.png" />
        <div />
      </Header>
      <Container style={{ paddingTop: '64px' }}>
        <CarouselImage
          src="https://img.agentaccount.com/d6b9b8b2b23af2766cd6855444005052003a328d"
          width="1116"
          height="774"
          alt="house"
          style={{ marginBottom: '32px' }}
        />
        <StyledAddress>Manly Vale 28/8 Koorala Street</StyledAddress>
        <Button id="make-an-offer">Make an offer</Button>
        <p>Guide: $640,000</p>
        <p>
          Located in the highly regarded 'The Peninsula' resort-style security
          complex, this one bedroom apartment is a great find for the owner
          occupier or investor.
        </p>
        <p>
          'The Peninsula' offers beautifully landscaped gardens and grounds and
          wonderful amenities which include a lovely indoor/outdoor heated pool,
          sauna and spa as well as a meeting room, games room and a
          well-equipped gym.
        </p>
      </Container>
    </Background>
  )
}

export default LJHSeaforth

/* global OfferKit */
/** @jsx jsx */
import { css, Global, jsx } from '@emotion/react'
import styled from '@emotion/styled'
import { useEffect } from 'react'

const Background = styled.div`
  display: block;
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  background-color: white;
`

const StyledLogo = styled.img`
  height: 24px;
  width: auto;

  @media screen and (min-width: 996px) {
    justify-content: flex-start;
    height: 30px;
  }
`

const MenuBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 64px;
  background-color: white;
  height: 64px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  position: relative;

  @media screen and (min-width: 996px) {
    justify-content: flex-start;
  }
`

const Carousel = styled.div`
  display: flex;
  align-items: stretch;
  height: 400px;
  overflow-x: hidden;
`

const CarouselImage = styled.img`
  height: 100%;
  width: auto;
`

const Content = styled.div`
  max-width: 700px;
  width: 100%;
  margin: 64px auto;
  display: grid;
  padding: 0;
  box-sizing: border-box;
`

const Button = styled.button`
  padding: 12px 24px;
  font-size: 14px;
  line-height: 1;
  text-transform: uppercase;
  width: 100%;
  display: inline-block;
  font-weight: 200;
  font-family: 'Martel Sans', sans-serif;
  transition: background 250ms ease-in-out;
  cursor: pointer;
  border-radius: 3px;

  background-color: #338fe8;
  color: #fff;
  border: 2px solid #338fe8;

  &:hover {
    color: #fff;
    opacity: 0.87;
  }
`

const Address = styled.h1`
  font-size: 32px;
  font-family: Martel, sans-serif;
  line-height: 1.4;
  font-size: 46px;
  margin-top: 0;
  font-weight: 400;
`

const SecondaryText = styled.p`
  font-family: 'Martel';
  font-weight: 600;
  letter-spacing: 1.6px;
  margin: 0;
  text-transform: uppercase;
  color: #676767;
`

const GlobalStyles = css`
  body {
    font-family: Martel, sans-serif;
  }

  .button-override {
    background: #142a53 !important;
  }
`

const DiJones = () => {
  useEffect(() => {
    OfferKit.exec('mount', {
      listingId: 'lst_offerkit-test-17-25-sutherland',
      selector: '#make-an-offer',
    })
    return () => {
      OfferKit.exec('unmount')
    }
  }, [])

  return (
    <Background>
      <Global styles={GlobalStyles} />
      <MenuBar>
        <StyledLogo src="https://cdn.dijones.com.au/wp-content/uploads/2018/10/24141030/di-jones-logo-blue-png.png" />
      </MenuBar>
      <Carousel>
        <CarouselImage
          src="https://propertyimages.stepps.net/7-dijones/large/DJNB3145-residential-8P10945-1594275313bAzPk4.jpg"
          width="887"
          height="591"
        />
        <CarouselImage
          src="https://propertyimages.stepps.net/7-dijones/large/DJNB3145-residential-8P10945-1594275313lSNSIy.jpg"
          width="887"
          height="591"
        />
        <CarouselImage
          src="https://propertyimages.stepps.net/7-dijones/large/DJNB3145-residential-8P10945-15942753131bep1s.jpg"
          width="887"
          height="591"
        />
        <CarouselImage
          src="https://propertyimages.stepps.net/7-dijones/large/DJNB3145-residential-8P10945-1594275313MZhKzH.jpg"
          width="887"
          height="591"
        />
      </Carousel>
      <Content>
        <div
          css={css`
            & {
              text-align: center;
            }
          `}
        >
          <SecondaryText
            css={css`
              & {
                margin-bottom: 24px;
              }
            `}
          >
            Apartment
          </SecondaryText>
          <Address>17/25 Sutherland Street, Cremorne</Address>
          <SecondaryText>
            IMPECCABLY APPOINTED APARTMENT AT HIGHLY DESIRABLE SUTHERLAND STREET
            ADDRESS
          </SecondaryText>
        </div>
        <div
          css={css`
            & {
              margin-top: 32px;
              text-align: center;
              padding: '0 16px';
            }
          `}
        >
          <Button
            id="make-an-offer"
            css={css`
              & {
                max-width: 180px;
                width: 100%;
              }
            `}
          >
            Make an offer
          </Button>
        </div>
      </Content>
    </Background>
  )
}

export default DiJones

/* global OfferKit */
import { css, Global } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useEffect } from 'react'

const Background = styled.div`
  display: block;
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  background-color: white;
`

const StyledLogo = styled.img`
  width: 250px;
  transform: translateY(-6px);
`

const MenuBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 32px 64px;
  background-color: white;
`

const Carousel = styled.div`
  display: flex;
  align-items: stretch;
  height: 400px;
  overflow-x: hidden;
`

const CarouselImage = styled.img`
  height: 100%;
  width: auto;
`

const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  display: grid;
  padding: 16px;
  box-sizing: border-box;

  @media (min-width: 640px) {
    max-width: 1024px;
    grid-template-columns: 2fr 1fr;
    grid-gap: 32px;
    padding-top: 64px;
  }
`

const Button = styled.button`
  background-color: #142a53;
  color: #fff;
  border: none;
  padding: 14px;
  font-size: 14px;
  line-height: 1;
  text-transform: uppercase;
  width: 100%;
  display: inline-block;
  font-weight: 700;
  font-family: inherit;
  transition: background 250ms ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #00a0d3;
  }
`

const Address = styled.h1`
  font-size: 32px;
  font-weight: 400;
  margin-top: 0;
`

const GlobalStyles = css`
  body {
    font-family: Lato, sans-serif;
  }

  .button-override {
    background: #142a53 !important;
  }
`

const Home = () => {
  useEffect(() => {
    OfferKit.exec('mount', {
      listingId: 'lst_offerkit-test-17-manning',
      selector: '#make-an-offer',
    })
    return () => {
      OfferKit.exec('unmount')
    }
  }, [])

  return (
    <Background>
      <Global styles={GlobalStyles} />
      <MenuBar>
        <StyledLogo src="north-south-logo.png" />
      </MenuBar>
      <Carousel>
        <CarouselImage
          src="https://www.cloudproperty.com.au/photos/view/88780/1070853/88780_1070853_1024x1024.jpg"
          width="994"
          height="745"
        />
        <CarouselImage
          src="https://www.cloudproperty.com.au/photos/view/88780/1070854/88780_1070854_1024x1024.jpg"
          width="1200"
          height="900"
        />
        <CarouselImage
          src="https://www.cloudproperty.com.au/photos/view/88780/1070855/88780_1070855_1024x1024.jpg"
          width="994"
          height="745"
        />
        <CarouselImage
          src="https://www.cloudproperty.com.au/photos/view/88780/1070856/88780_1070856_1024x1024.jpg"
          width="994"
          height="745"
        />
      </Carousel>
      <Content>
        <div>
          <Address>17 Manning Street, Milton</Address>
        </div>
        <aside>
          <Button id="make-an-offer">Make an offer</Button>
        </aside>
      </Content>
    </Background>
  )
}

export default Home
